import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />

      <div className="flex flex-col w-full items-center justify-center max-w-2xl mx-auto px-4 text-center space-y-8">
        <div className="w-24 md:w-48">
          <StaticImage
            alt="Exobyte"
            placeholder="tracedSVG"
            src="../images/logo-full.png"
          />
        </div>

        <h1 className="font-semibold text-[#242D3F] text-3xl leading-tight sm:text-4xl sm:leading-tight">
          It's been real.
        </h1>

        <p>
          Exobyte has officially decided to close its doors. While it's been a fun and wild ride trying to
          grow Exobyte into a successful agency, a mutual decision was made to pursue other career paths. Both Taylor and Dominic
          have moved on to full-time positions with other companies doing what they love most.
        </p>

        <p>
          We want to personally thank all of our amazing clients who gave us the opportunity to transform their
          business in some way. We hope that the products and solutions we designed and implemented for you to continue
          to make an impact.
        </p>

        <p>
          - Taylor & Dominic
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
